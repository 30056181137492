<template>
  <DefaultLayout>
    <v-container fill-height>
      <v-row>
        <v-col cols="12" sm="6" md="4" lg="3" xl="2" class="pa-3 d-flex flex-column" v-for="(s, i) in status" :key="i">
          <v-card class="pa-2 elevation-5 flex d-flex flex-column" :color="getColor(s.status)">
            <v-card-title>{{ $t(`status.${i}.title`) }}</v-card-title>
            <v-divider></v-divider>
            <v-list dense>
              <v-list-item v-for="(k, v) in s.data" :key="v">
                <v-list-item-content>{{ v }}</v-list-item-content>
                <v-list-item-content class="align-end">{{ k }}</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>

      <v-navigation-drawer v-model="drawer" right permanent absolute>
        <v-list dense nav class="py-0">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Test Funktionen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item link @click="testAction('sendTestMail')">
            <v-list-item-icon>
              <v-icon>mdi-email-check-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Send test mail to myself </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link @click="$store.dispatch('showSnackMessage', { message: 'Test message, Test message, Test message', timeout: 5000 })">
            <v-list-item-icon>
              <v-icon>mdi-bell-ring</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Show snack</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-container>
  </DefaultLayout>
</template>

<script>
import axios from "axios";

import { mapGetters } from "vuex";

import DefaultLayout from "@/layouts/default.vue";

export default {
  name: "adminhome",
  components: {
    DefaultLayout,
  },
  data: function() {
    return {
      status: {},
      drawer: null,
    };
  },
  computed: {
    ...mapGetters(["snack"]),
  },
  watch: {},
  mounted() {
    this.loadStatus();
  },
  methods: {
    loadStatus: function() {
      this.$store.dispatch("loading", true);
      axios
        .get(`/api/admin/status`)
        .then((res) => {
          this.status = res.data;
          this.$store.dispatch("loading", false);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    testAction: function(action) {
      this.$store.dispatch("loading", true);
      axios
        .get(`/api/admin/test/${action}`)
        .then(() => {
          this.$store.dispatch("loading", false);
          this.$store.dispatch("showSnackMessage", { message: "Test mail sent", color: "green" });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getColor: function(status) {
      if (status == "ok") {
        return "lime";
      }

      return "red lighten-1";
    },
  },
};
</script>

<style lang="scss"></style>
